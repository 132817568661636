.navbar_dimond_logo::after {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-image: url(../../assets/images/Sparkling-Diamond.svg);
    background-repeat: no-repeat;
    /* background-position: right center; */
    background-size: 1.5rem 1.5rem;
    content: "";
    transition: 0.3s;
    margin-left: auto;
}
.navbar_dimond_logo_blue::after {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-image: url(../../assets/images/unblock_premium_dimond_logo.svg);
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    content: "";
    transition: 0.3s;
    margin-left: auto;
}
.navbar_unblock_premium_nav_item {
    box-sizing: border-box;
    background: #303F66;
    border: 1px solid #09A5BE;
    border-radius: 6px;
    padding-bottom: -25px;
    margin-top: 10px;
}

.navbar_unblock_premium_nav_item_inner_div {
    padding: 10px 0px;
    margin: auto;
    font-weight: 600;
    font-family: 'Red Hat Display 600';
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
}

.navbar_unblock_premium_nav_item_inner_div>img {
    background-color: #09A5BE;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 16px;
}

.navbar-brand-logo {
    margin-left: -35px;
}

.navbar-brand-logo-text {
    margin-top: 20px;
    margin-left: -16px;
    font-weight: 700;
    font-family: 'Red Hat Display 700';
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.navbar-brand-logo-text_orange {
    margin-left: 6px;
    color: #F79423;
}

.navbar-vertical-aside-mini-mode .navbar-brand-logo-text {
    display: none;
}

.navbar-vertical-aside-mini-mode .navbar_unblock_premium_nav_item {
    display: none;
}
.sidebar-bi-gem-icon{
    display: none;
}
.navbar-vertical-aside-mini-mode .sidebar-bi-gem-icon {
    display: block;
}

.navbar-nav-download-csv-btn {
    height: 40px;
    width: 227px;
    box-sizing: border-box;
    border: 1px solid #377DFF;
    border-radius: 8px;
    background-color: #ffffff;
    font-weight: 600;
    font-family: 'Red Hat Display 600';
    font-size: 16px;
    line-height: 21px;
    color: #377DFF;
}
.navbar-back-domain-span > .bi-arrow-left{
    background-color: #031762;
    color: #0528aa;
}
.navbar-back-domain-span{
    margin-left: 15px;
font-weight: 400;
font-family: 'Red Hat Display';
font-size: 14px;
line-height: 19px;
color: #000000;

}
.navbar-aside-toggler{
z-index: 99999999;
}
.width_height_25
{
    width: 25px;
    height: 25px;
}
.padding_nav_title
{
    padding-left: 20px;
    align-items: center;
    display: flex;
}
.border-top_custom
{
    border-top: 0.1px solid;
}
.opacity_1
{
    opacity: 1 !important;
}
.dashboard_home_page_recent_result_div_heading
{
    margin-left: 8px;
}
.margin_left_16
{
    margin-left: -16.25rem !important;
}
/* @media only screen and (max-width: 600px) {
    .margin_left_16
{
    margin-left: -16.25rem !important;
}
  } */

  .slide_right_menu {
    position: absolute;
    display: flex;
    -webkit-animation: slide_right_menu 0.5s forwards;
    -webkit-animation-delay: 0s;
    animation: slide_right_menu 0.5s forwards;
    animation-delay: 0s;
}

/* @-webkit-keyframes slide_right_menu {
    100% {
        left: -20;
    }
} */
.js-navbar-vertical-aside
{
    transition: width 0.3s;
}

body
{
    /* transition: width 10s; */
    transition: 0.5s padding ease-out, 
}
