body {
  overflow: hidden !important;
  margin: 0 !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.text_14_400 {
  font-family: "Red Hat Display";
  font-size: 14px;
  /* font-weight: 400; */
  line-height: 19px;
  letter-spacing: 0em;
}

.text_12_700 {
  font-family: "Red Hat Display";
  font-size: 12px;
  font-weight: 700;
  color: black;
  letter-spacing: 0em;
}

.dropdown-item {
  font-family: "Red Hat Display 600";
  font-size: 14px;
  line-height: 19px;
}

.cursor-na {
  cursor: not-allowed !important;
}

.ml_auto {
  margin-left: auto;
}

.text_64_900 {
  font-family: "Red Hat Display 900";
  font-size: 64px;
  font-weight: 900;
  line-height: 85px;
  letter-spacing: 0em;
}

.text_15_400 {
  font-family: "Red Hat Display";
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
}

.text_15_600 {
  font-family: "Red Hat Display 600";
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
}

/* text typography */
.text_16_400 {
  font-family: "Red Hat Display";
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
}

.text_16_600 {
  font-family: "Red Hat Display 600";
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0em;
}

.text_16_900 {
  font-family: "Red Hat Display 900";
  font-size: 16px;
  font-weight: 900;
  line-height: 21px;
  letter-spacing: 0em;
}

.custom_loader {
  margin-left: 30vh;
}

/* @media only screen and (max-width: 600px) {
    .custom_loader
    {
        margin-left: 0vh;
    }
  } */

.text_15_700 {
  font-family: "Red Hat Display 700";
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
}

.text_15_500 {
  font-family: "Red Hat Display 500";
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.mid_verify_card {
  background: white;
  height: 140px;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 700px !important;
  justify-content: center;
}

.mid_password_card {
  background: white;
  height: fit-content;
  padding-top: 24px;
  padding-bottom: 12px;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 700px !important;
}
.align_self_center {
  align-self: center;
}
.min_width_137 {
  min-width: 137px !important;
}

.change_password_btn {
  padding: 4px;
  margin-top: 10px !important;
  width: 139px !important;
  background: #377dff;
  border-radius: 9px;
  font-family: "Red Hat Display 500";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  align-items: center;
  color: #ffffff;
  border: none;
  text-align: center;
}

.text_16_500 {
  font-family: "Red Hat Display 500";
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21.7px !important;
}

.text_24_700 {
  font-family: "Red Hat Display 700";
  font-size: 24px !important;
  font-weight: 700 !important;
}

.text_16_700 {
  font-family: "Red Hat Display 700";
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 21.7px !important;
}

.text_14_600 {
  font-family: "Red Hat Display 600";
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
}

.text_12_500 {
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 19.85px !important;
  font-family: "Red Hat Display 600";
}

.text-13-500 {
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 17.3px !important;
  font-family: "Red Hat Display 500";
}

.text_13_600 {
  font-size: 13px !important;
  font-weight: 600 !important;
  line-height: 17.3px !important;
  font-family: "Red Hat Display 600";
}

.width_29 {
  width: 29px !important;
}

.text_32_700 {
  font-size: 32px !important;
  font-weight: 700 !important;
  line-height: 42.34px !important;
  font-family: "Red Hat Display 700";
}

.text_20_700 {
  font-size: 20px !important;
  font-weight: 700 !important;
  line-height: 26.46px !important;
  font-family: "Red Hat Display 700";
}

.text_20_600 {
  font-family: "Red Hat Display 600";
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.text_23_700 {
  font-family: "Red Hat Display 700";
  font-size: 23px !important;
  font-weight: 700 !important;
  line-height: 30px !important;
  letter-spacing: 0em !important;
}

.height-40 {
  height: 37px;
}

.text_24_700 {
  font-family: "Red Hat Display 700";
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}

.invalid-feedback_custom {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: red;
}

.text_20_500 {
  font-family: "Red Hat Display 500";
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
}

.text_20_400 {
  font-family: "Red Hat Display";
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
}

.text_13_800 {
  font-family: "Red Hat Display 800";
  font-size: 13px !important;
  font-weight: 800 !important;
  line-height: 17.3px !important;
  font-family: "Red Hat Display";
}

.text_12_700 {
  font-family: "Red Hat Display 700";
  font-size: 12px !important;
  font-weight: 800 !important;
  line-height: 9.3px !important;
  font-family: "Red Hat Display";
}

.bg-danger_legend {
  background: #ff403f;
}

.bg-warning_legend {
  background: #fddd60;
}

.bg-success_legend {
  background-color: #64c88a;
}

.bg_orange_legend {
  background: #faad14;
}

.padding_left_0 {
  padding-left: 0px;
}

.margin_right_0 {
  margin-right: 0px;
}

.text-danger_legend {
  color: #ff403f;
}

.text-warning_legend {
  color: #fddd60;
}

.text-success_legend {
  color: #64c88a;
}

.text_orange_legend {
  color: #f56b38;
}

.doted_list {
  width: 10px;
  height: 10px;
  margin-right: 15px;
  margin-top: 0px;
  border-radius: 13px;
}

.label_card_list {
  color: #8c98a4;
}

.text_orange {
  color: #fd7e14;
}

.bg_orange {
  color: #fd7e14;
}

.text_14_500 {
  font-family: "Red Hat Display 500";
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 19px !important;
}

.text-dark_grey {
  color: #5a5a5a;
}

.text_14_700 {
  font-family: "Red Hat Display 700";
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 19px !important;
  letter-spacing: 0em !important;
}

.text_36_800 {
  font-family: "Red Hat Display 800";
  font-size: 36px !important;
  font-weight: 800 !important;
  line-height: 48px !important;
}

.text_color_377DFF {
  color: #377dff !important;
}

.text_grey {
  color: grey;
}

.float_right_text {
  float: right !important;
}

.font_roboto {
  font-family: "Roboto" !important;
}

.font_red_heat {
  font-family: "Red Hat Display";
}

.text_black {
  color: black;
}

/*end text typography */
/* custom padding  */
.padding_dashboar_card {
  padding-right: 1.68rem !important;
  padding-left: 1.6rem !important;
}

.margin_left_16 {
  margin-left: 16px !important;
}

.margin_minus_240 {
  margin-left: -260px !important;
}

.margin_minus {
  margin-left: -260px !important;
}

.padding_50_left_right {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

/* custom margin  */
.margin_left_48 {
  margin-left: 48px !important;
}

.margin_left_18 {
  margin-left: 18px;
}

.margin_left_20 {
  margin-left: 20px !important;
}

.imgsectionaddprofilePic {
  width: 10%;
}

.margint_top_46 {
  margin-top: 46px !important;
}

.margin_top_30 {
  margin-top: 30px;
}

.background_singupImage {
  background: black;
  background-image: url(../src/assets/images/blurrytestmore.png);
  position: absolute;
  background-repeat: no-repeat;
  height: -webkit-fill-available;
  padding: 0px !important;
  margin: 0px !important;
  width: -webkit-fill-available;

  background-size: cover;
}

.margin_right_10 {
  margin-right: 10px;
}

.text-align_center {
  text-align: center;
}

.margin_left_72 {
  margin-left: 72px;
}

.margin_left_118 {
  margin-left: 118px;
}

/* custom width, heght and size  */
.size_88 {
  width: 88px;
  height: 88px;
}

.size_20_X_26 {
  width: 20px;
  height: 26px;
}

.size_12 {
  width: 12px;
  height: 12px;
}

.width_260 {
  width: 260px;
}
.width_400 {
  width: 600px !important;
}
.width-90 {
  width: 90% !important;
}

.height_fit_content {
  height: fit-content !important;
}

.height_15 {
  height: 15px !important;
}

.height_375 {
  height: 357px;
}
.width_576 {
  width: 576px;
}

.g2-tooltip {
  display: block !important;
}

.padding_10 {
  padding-left: 10px !important;
}

.padding_left_33 {
  padding-left: 33px !important;
}

.nav_link_active {
  background: #354160 !important;
  /* color: var(--bs-nav-link-color); */
  color: #fff !important;
}

.ant-tooltip-arrow {
  overflow: initial !important;
}

.button_tooltip {
  width: 5px !important;
  padding: 0px !important;
  height: 18px !important;
}

.organic_keyword_chart_icon {
  margin-top: -2px;
  font-size: 38px;
  margin-right: 15px;
  color: red !important;
}
.padding_left_2_pint_2 {
  padding-left: 1.9rem !important;
}

.text_12_700_test_family {
  font-family: "Courier New", Courier, monospace;
}

.table_main_div {
  margin: 24px;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
}

.create_new_input {
  min-width: 278px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 9px;
  padding-left: 20px;
  font-weight: 300;
  font-size: 16px;
  color: black;
}
/* .ant-table-cell,.ant-table-row{
    text-align: center !important;
} */

.ant-spin-dot-item {
  background-color: #20356b !important;
}

.table_main {
  height: calc(100vh - 300px);
}

.table_tag {
  height: calc(100vh - 380px) !important;
}

.drawer_span {
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5rem !important;
  font-size: 0.875rem !important;
  color: #1e2022 !important;
}
.drawer_span_no-margin {
  display: block;
  font-size: 0.875rem !important;
  color: #1e2022 !important;
}

/* .drawer_input {
  border: 1px solid rgb(218, 217, 217) !important;
  box-shadow: 0 0 10px 0 rgb(226, 226, 226);
} */
.ant-doctor_drawer-content-wrapper {
  /* max-width: 100vh !important; */
  width: 70% !important;
}
.select__input {
  padding: 5px !important;
}
.multiSelectBox {
  background: none !important;
  padding: 0 !important;
  border: 2px none !important;
  /* box-shadow: 0 0 10px 0 rgb(226, 226, 226); */
}

.navbar-vertical.navbar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 16.25rem;
}

.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  width: 10.75rem;
}

/* .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
  padding-left: 5.75rem;
}
.navbar-vertical-aside-show-xl .main {
  padding-left: 16.25rem;
} */
.margin_minus_240 {
  margin-left: 0px !important;
}

/************* THEME **************/

.bg-theme {
  background-color: #20356b !important;
}

.btn-theme {
  opacity: 1;
  background-color: #20356b !important;
  color: white !important;
}
/************* BUTTON **************/
.btn-theme:hover {
  box-shadow: 0 0 10px 0 #20356b !important;
}

/* .delete-table-btn:hover {
  box-shadow: 0 0 10px 0 #d70000 !important;
}
.view-table-btn:hover {
  box-shadow: 0 0 10px 0 #808080 !important;
}
.edit-table-btn:hover {
  box-shadow: 0 0 10px 0 #20356b !important;
} */
.form-check-input:checked {
  background-color: #20356b !important;
  /* #132144 */
}
.ant-drawer-body {
  padding: 12px !important;
  background-color: #6777882e !important;
}

.drawer_main_div {
  padding: 20px 0 !important;
  margin: 0 !important;
  background: #fff !important;
  border: 1px solid #dbdbdb !important;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.14) !important;
  border-radius: 10px !important;
  min-height: 100% !important;
}
.navbar-brand-logo {
  width: 16rem !important;
  text-align: center !important;
  margin: auto !important;
  margin-top: 20px !important;
}
.nav-item:hover {
  cursor: pointer !important;
}
.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo {
  display: none;
}

.navbar-vertical-aside-mini-mode
  .navbar-vertical-aside
  .navbar-brand-logo-mini {
  display: block;
}
/* .navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .navbar-vertical-aside .navbar-brand-logo-mini {
  display: block !important;
} */

.btn-loading {
  background-color: whitesmoke !important;
  border: 1px solid #20356b !important;
  color: #20356b !important;
  box-shadow: 0 0 10px 0 #20356b !important;
}
/* .ant-notification-notice,
.ant-notification-notice-error,
.ant-notification-notice-closable {
  background-color: #667bb1 !important;
  box-shadow: 0 0 5px 0 whitesmoke !important;
} */
.ck-toolbar {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
  border-bottom: none !important;
}
.select__control {
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}
.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 300px;
  overflow-y: auto;
  border: 0.0625rem solid rgba(231, 234, 243, 0.7) !important;
}

/* .ck-toolbar-error {
  border: 0.0625rem solid red !important;
  border-bottom: none !important;
}
.ck-editor__editable_inline:not(.ck-comment__input *) {
  height: 300px;
  overflow-y: auto;
  border: 0.0625rem solid red !important;
  background-color: red;
} */

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #20356b !important;
}

.services-section-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}
.services-section-grid-container > .pointer {
  overflow: hidden;
  height: fit-content;
  text-align: center;
  height: 3rem;
  display: flex !important;
  align-items: center;
  flex-direction: row;
}

.arrow {
  position: relative;
  background: rgba(113, 134, 157, 0.1);
  width: 70%;
  height: 3rem;
  text-align: center;
  line-height: 3rem;
  margin-bottom: 10px;
  border-left: 8px solid rgba(113, 134, 157);
  color: rgba(113, 134, 157);
}

.arrow:before {
  border: solid transparent;
  content: " ";
  position: absolute;
  border-bottom-color: rgba(113, 134, 157, 0.1);
  border-width: 1.5rem;
  left: 100%;
  justify-content: center;
  align-items: center;
  transform: rotate(90deg);
}

.why-choose-us-section-item .why-choose-us-section-item-div {
  background-color: #ffe7d7;
  border-right: 10px solid #fb8a3e;
  padding: 2.5rem 2rem;
  width: 100%;
  margin-right: 1rem;
  z-index: 2;
  overflow-wrap: anywhere;
}

.why-choose-us-section-item-icon-span-border {
  font-size: 1.5rem;
  padding: 0 0.6rem;
  height: fit-content;
  margin: auto;
  position: relative;
  border-radius: 50%;
  left: 3.6rem;
  padding: 0.6rem 0.2rem;
  border: 10px solid #fb8a3e;
}
.why-choose-us-section-item-icon-span {
  font-size: 1.5rem;
  background-color: #fb8a3e;
  padding: 0 0.6rem;
  border-radius: 50%;
  padding: 0.6rem 0.7rem;
  border-left: 2px solid #fb8a3e;
  z-index: 99999999;
  position: relative;
}

.why-choose-us-section-item-icon-span > .SafetyCertificateOutlined {
  color: #fb8a3e;
  background-color: white;
  border-radius: 50%;
  padding: 0.2rem;
}

.about-grid-container {
  display: grid;
  /* width: 100%; */
  grid-template-columns: repeat(auto-fill, minmax(209px, 1fr));
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  gap: 20px;
  padding: 20px;
  margin: auto;
}
.about-grid-container-card {
  color: #17425f;
  border: 1px solid #5ba0cc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* min-height: 155px; */
  border-radius: 9px;
  padding: 1rem 0.6rem;
  overflow-wrap: break-word;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.14) !important;
}
.about-grid-container-card h1 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0;
}
.about-grid-container-card p {
  font-size: 16px;
  font-weight: 400;
}
.about-grid-container-card > span {
  background-color: #97d7ff;
  border: 1px solid #5ba0cc;
  width: fit-content;
  padding: 0.6rem;
  border-radius: 50%;
}
.about-grid-container-card img {
  height: 27px;
  width: 27px;
}
/* .services-section-grid-container > .pointer {
  overflow: hidden;
  height: fit-content;
  text-align: center;
  height: 3rem;
  display: flex !important;
  align-items: center;
  flex-direction: row;
} */

.about-price-section {
  margin-top: 2rem;
  /* display: flex; */
}
.about-price-section > div {
  /* width: 50%; */
  margin: 1rem;
  border: #fb8c3e 1px solid;
}

.about-price-section > div > h1 {
  background: linear-gradient(#edb9b9, #ffd1b4);
  font-size: 22px;
  text-align: center;
  padding: 1rem 0;
  font-weight: 600;
  color: #17425f;
  border-bottom: #fb8c3e 1px solid;
}

.about-price-section-item {
  display: flex;
  justify-content: space-between;
  margin: 0 2rem;
  font-size: 16px;
  font-weight: 500;
  padding: 0.6rem 0;
}
.about-price-section-name {
  width: 80%;
  overflow: hidden;
  overflow-wrap: anywhere;
}
.about-price-section-price {
  width: 20%;
}
.about-price-section-item > p {
  display: flex;
  justify-content: space-between;
  margin: 0 1rem;
}
.about-price-section-card > div {
  border-bottom: #bababa 0.8px solid;
}

.about-price-section-card :last-child {
  border-bottom: none; /* Remove the border from the last element */
}

.doctor-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(209px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: auto;
}

.doctor-grid-container .img-div {
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 0 40px 0px 00px;
}

.doctor-grid-container-card .doctor-name {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  padding: 1rem 0;
}
.doctor-grid-container-card .doctor-details {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.doctor-grid-container-card {
  box-shadow: 0 0 10px 0 #17425f !important;
  border-radius: 0 40px 0px 40px;
}

.why-choose-us-list-section {
  display: flex;
}

.why-choose-us-list-section-list {
  width: 40%;
  background-color: rgb(180, 180, 214);
  padding-left: 2.5rem;
}

.why-choose-us-list-section-list-item {
  display: flex;
  margin: 0.6rem 0;
}

.why-choose-us-list-section-list-item img {
  height: 70px;
  position: relative;
  left: -2.2rem;
}

.why-choose-us-list-box {
  background-color: #ffe7d7;
  border-right: 5px solid #fb8a3e;
  width: 90%;
}

.why-choose-us-section-item img {
  height: 4rem;
}
.why-choose-us-section-item-icon-div {
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: inherit;
  position: relative;
  right: -1.6rem;
  z-index: 9;
}
.why-choose-us-section-item-div hr {
  color: #fb8a3e;
}

.doctor-grid-container-card .close-icon {
  position: relative;
  left: 0.3rem;
  top: 0.3rem;
  cursor: pointer;
}
.language-segmented {
  display: flex;
  /* justify-content: end; updated that to  flex-end as warning remove if face any issues*/ 
  justify-content: flex-end;
}

.drawer_area_main_div {
  padding: 20px 0 !important;
  margin: 0 !important;
  background: #fff !important;
  border: 1px solid #dbdbdb !important;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.14) !important;
  border-radius: 10px !important;
  min-height: 100% !important;
}
.card {
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.14) !important;
}
.required:after {
  content: " *";
  color: red;
}

.dropdown-menu {
  z-index: 99999 !important;
}

.navbar-vertical-aside-mini-mode .nav-vertical.nav-pills .nav-collapse {
  margin-top: -2rem !important;
  /* background-color: rgb(0, 255, 115); */
  opacity: 1;
}
.ant-table-cell {
  color: #677788;
}

.select__multi-value {
  background-color: rgba(113, 134, 157, 0.1) !important ;
  color: #677788 !important;
}

.dropdown-item .text-body {
  text-wrap: wrap;
}

.select__input {
  padding: 5px 0 !important;
}
/* 
.select__menu {
  background-color: rebeccapurple !important;
} */
/* .ant-table-body{
  
} */
.ant-select-selector {
  border-radius: 5px !important;
  padding: 5px 10px !important;
  height: 2.8rem !important;
}
.ant-select {
  border-radius: 5px !important;
}

.bulk-facility-main-div .ant-table-body {
  height: 40vh !important;
}
.g2-tooltip {
  display: none !important;
}

.g2-tooltip-name::after {
  content: "count" !important;
}

.request-main-div .ant-table-body {
  height: 40vh !important;
}

.ant-modal-content {
  border-radius: 0.75rem;
  background-clip: border-box;
  border: 0.2rem solid rgba(231, 234, 243, 0.7);
  --bs-card-spacer-y: 1.3125rem;
  --bs-card-spacer-x: 1.3125rem;
  --bs-card-title-spacer-y: 0.25rem;
  --bs-card-border-width: 0.0625rem;
  --bs-card-border-color: rgba(231, 234, 243, 0.7);
  --bs-card-border-radius: 0.75rem;
  --bs-card-box-shadow: 0rem 0.375rem 0.75rem rgba(140, 152, 164, 0.075);
  --bs-card-inner-border-radius: 0.6875rem;
  --bs-card-cap-padding-y: 1.3125rem;
  --bs-card-cap-padding-x: 1.3125rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.3125rem 1.3125rem;
  --bs-card-group-margin: 0.75rem;
}




.toggle-button-cover {
  position: relative;
  width: 200px;
  height: 140px;
  box-sizing: border-box;
}

.button-cover {
  height: 100px;
  margin: 20px;
  background-color: #fff;
  box-shadow: 0 10px 20px -8px #c5d6d6;
  border-radius: 4px;
}

.button-cover:before {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #d7e3e3;
  font-size: 12px;
  /* line-height: 1; */
  /* padding: 5px; */
}

.button-cover,
.knobs,
.layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.button-toggles {
  position: relative;
  top: 50%;
  width: 74px;
  height: 36px;
  margin: -20px auto 0 auto;
  overflow: hidden;
}

.button-toggles.r,
.button-toggles.r .layer {
  border-radius: 100px;
}

.button-toggles.b2 {
  border-radius: 2px;
}

.checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}

.knobs {
  z-index: 2;
}

.layer {
  width: 100%;
  background-color: #ebf7fc;
  transition: 0.3s ease all;
  z-index: 1;
}

/* Button 1 */
#button-1 .knobs:before {
  content: "No";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 30px;
  height: 80%;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  padding: 9px 4px;
  background-color: #03a9f4;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}

#button-1 .checkbox:checked + .knobs:before {
  content: "Yes";
  left: 42px;
  background-color: #f44336;
}

#button-1 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}

#button-1 .knobs,
#button-1 .knobs:before,
#button-1 .layer {
  transition: 0.3s ease all;
}

.height-100vh{
  height: 60vh;
}

.anchor-color{
  color: var(--bs-link-color);
}
.w-90{
  width: 88% !important;
}
.w-10{
  width: 12% !important;
}

.my-custom-table {
  width: 100%;
  border-collapse: collapse;
}

.my-custom-table th, .my-custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}
.my-custom-table td {
  word-wrap: break-word;  /* Wraps the word to the next line */
    word-break: break-all;
}

.my-custom-table th {
  background-color: #f4f4f4;
}
.my-custom-table-container {
  max-height: 1400px; 
  overflow-y: scroll;  
  overflow-x: visible;  
}

.custom-select-container-content {
  position: relative;
  width: 12vw;
  border-radius: 4px;
  cursor: pointer;
  height: 43px;
}

.custom-select-container {
  position: relative;
  /* width: 125px; */
  width: 10vw;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  height: 43px;
  /* margin-right: 20px; */
}
.custom-select-container-select{
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  height: 43px;
  /* width: 193px; */
  width: 10vw;
}

.selected-items {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  /* border: 1px solid #ccc; */
}

.dropdown-custom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
  z-index: 999;
}

.checkbox-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.checkbox-list li {
  padding: 5px;
}

.checkbox-list label {
  cursor: pointer;
}

.checkbox-list input[type="checkbox"] {
  margin-right: 5px;
}


/* .ant-table-body{
      overflow-y: unset !important;
}

.request-main-div{
  height:max-content;
}
.table_main{
  height: 100vh;
} */


.btn-custom-height{
  height: 43px !important;
  margin-top: 2.86rem;
}
.row-isNew{
  background-color: #dbedfe;
}
.no-event{
  text-align: center;
    display: block;
    width: 100%;
}
th{
  z-index:0;
}

.table_main{
  height:auto  !important;
}

.table_tag{
  height:auto  !important;
}
table{
  height:auto  !important;
}

.ant-table-body{
  max-height: max-content !important;
  height:auto  !important;
}
.ww-50{
  width: 50% !important;

}
.d-flex-custom{
  display: flex !important;
}
.text-end-or-center{
  text-align: right !important;
}
.filter-search-btn{
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
}
@media (min-width: 100px) and (max-width: 500px) {
.text-end-or-center{
  text-align: center !important;
}
.dashboard_content_main > div > div {
  flex-direction: column;
  align-items: flex-start;
}
.dashboard_profile_right_div {
  width: 100% !important;
}
.dashboard_profile_right_div_input_box_div{
  flex-wrap: wrap;
}
.MuiDialog-paper{
  display: contents !important;
}
.ant-drawer-header{
  flex-direction: column;
}
.ant-drawer-extra{
  margin-top: 10px;
}
.language-segmented{
  width: inherit;
  overflow-y: scroll;
  flex-direction: column;
}
}

@media (min-width: 100px) and (max-width: 760px) {
  .filter-search-btn{
  margin-top: 10px !important;
  }
  .ww-50{
    width: 100% !important;
  }
.ww-50 > div:nth-child(2) button {
    margin-top: 10px;
  }
  .ant-drawer-content-wrapper{
    width: 100% !important;
  }
  .ant-upload-select{
    margin-top: 10px;

  }
  .d-flex-custom{
    display: block !important;
  }
  .buttons-on-title{
    display: flex;
    flex-direction: row
  }
  .custom-select-container {
width: 100%;
}
.custom-select-container-content{
  width: 100%;
  justify-content: flex-end;
}
.custom-select-container-select{
  width: 100%;
  justify-content: flex-end;
}
.table_main_div {
  margin: 16px;
}

.my-custom-table-container {
  overflow-x: auto; 
  overflow-y: auto;
  max-width: 100%;
}

.my-custom-table {
  width: auto; 
}
.drawer_input{
  width: 100%;
}
.ant-image{
  width: 30px !important;
}
.navbar-vertical-aside-mini-mode .navbar-vertical-aside {
  width: 0px;
}
.navbar-vertical-aside-show-xl.navbar-vertical-aside-mini-mode .main {
  padding-left: 0rem;
}
.navbar {
  transition: none !important; /* Remove any transition to test */
}
.navbar-vertical-aside .navbar-aside-toggler {
  position: absolute;
  top: 2.734375rem;
  right: -2rem;
}


}

