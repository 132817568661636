.dashboard_profile_main {
    margin-top: 49px;
}

.dashboard_profile_left_div {
    padding-bottom: 20px;
    margin-left: 20px;
    width: 306px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    height: 278px;
}

.dashboard_profile_left_div_nav_item {
    padding: 15px 15px;
}

.dashboard_profile_left_div_nav_item>i {
    font-size: 21.09px;
}

.dashboard_profile_left_div_nav_item>span {
    margin-left: 28.5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #677788;
}


.dashboard_profile_right_div {
    width: 821px;
}

.dashboard_profile_right_div_item_div {
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    margin-right: 19.5px;
    margin-left: 19.5px;
    margin-bottom: 32px;
}

.dashboard_profile_right_div_item_div>h5 {
    font-family: 'Red Hat Display 500';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin: 21px 27px;


}


.dashboard_profile_right_div_item_btn_div {
    text-align: right;
}

.dashboard_profile_right_div_item_btn_div>button {
    background: #377DFF;
    border-radius: 4px;
    width: 150px;
    height: 42px;
    border: none;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 22px 20px;
}

.dashboard_profile_right_div_input_box_div {
    padding: 7px 0px;

}

.dashboard_profile_right_div_input_box_div>span {
    min-width: 189px;
    margin: auto;
    font-family: "Red Hat Display 600";
    margin-left: 27px;

}

.dashboard_profile_right_div_input_box_div>input {
    margin-right: 20px;
    
}

.dashboard_profile_delete_btn {
    background: #ED4C78 !important;
}

.dashboard_profile_right_div_input_box_div>.form-check-input {
    margin-left: 32.5px;
    height: 14px;
    width: 14px;
    border: 1px solid #677788;

}

.dashboard_profile_right_div {
    margin: auto;
}

.text_profile_link {
    margin-left: 28.5px;
    font-weight: 500;
    font-family: "Red Hat Display 500";
    font-size: 14px;
    line-height: 19px;
    color: #677788;
}

.border_20px {
    height: 81px;
    padding-top: 4px;
    width: 81px;
    padding-left: 4px;
    border: 1px solid;
    border-radius: 39px;
    justify-content: center;
    display: flex;
    background: #FAFAFA;
}

.plusOutline {
    margin-left: 13px;
    font-size: 20px;
    pointer-events: 'cursor';
}

.box_shadow_0_0_0_0_2 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.width_149 {
    width: 153px;
}

.border_50 {
    border-radius: 50px;
}

.size_100 {
    height: 100px;
    width: 100px;
}

