.dashboard_domain_traffic_state_graph_main_div{
    margin: 0px 33px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14) !important;
    padding-bottom: 40px;
}
.dashboard_domain_traffic_state_graph_main_div_heading{
    padding-left: 26px;
    padding-top : 25px;
    font-weight: 800;
font-size: 16px;
line-height: 21px;

color: #000000;
}

.margin_red_flag
{
    margin-left: 0px !important;
    margin-right: 32px !important;
}
.dashboard_domain_traffic_state_red_flag_siren_icon
{
    width: 22.81px;
    height: 26.88px;
    margin-left: 31.1px;
    margin-right: 11.09px;
}
.dashboard_domain_traffic_state_check_icon
{
    height: 7.5px;
    width: 10.5px;
    margin-left: 31.1px;
    margin-right: 11.09px;
}
