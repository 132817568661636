.dashboard_home_page_country_react_select{
    margin-top: -10px !important;
    box-sizing: border-box !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 9px !important;
    width: 278px !important;
    height: 48px !important;
    text-align: left;
    border: none;
   
}
.dashboard_home_page_country_react_select>div{
    /* min-height: 48px !important; */
    margin-top: 4px;
    /* border-radius: 9px !important; */
    box-shadow: 0 !important;
}
.dashboard_home_page_country_react_select >div>div>span{
    display: none;
}




.dashboard_home_page_country_react_select_error {
    border: 1px solid red !important;
    margin-top: -10px !important;
    box-sizing: border-box !important;
    border-radius: 9px !important;
    width: 278px !important;
    height: 48px !important;
    text-align: left;
    border: none;
}
.dashboard_home_page_country_react_select_error>div{
    /* min-height: 48px !important; */
    margin-top: 4px;
    /* border-radius: 9px !important; */
    box-shadow: 0 !important;
}
.dashboard_home_page_country_react_select_error >div>div>span{
    display: none;
}

.dashboard_home_page_country_react_select_error:focus {
    outline-offset: 0px !important;
    outline: none !important;
    border: 1px solid red !important;
    box-shadow: 0 0 3px red !important;
    -moz-box-shadow: 0 0 3px red !important;
    -webkit-box-shadow: 0 0 3px red !important;
}