.bg_theme_color_blue {
  background-color: #20356b;
}
.login_text_register_blue {
  color: #20356b;
}

.login_page_min_height {
  min-height: 100vh;
}

.login_page_right_div_btn {
  width: 180px;
  height: 48px;
  color: #20356b;
  background-color: #ffffff;
  border: 1px solid #20356b;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  margin: 0.625rem 0.219rem;
}

.login_left_div_logo {
  margin-top: 5.938rem;
}

.login_page_heading {
  margin-top: 1.188rem;
  font-style: normal;
  font-family: "Red Hat Display 800";
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  color: #000000;
}

.login_left_div_labels_Email {
  margin-top: 2rem;
  width: 278px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  font-family: "Red Hat Display 500";
  color: #000000;
}

.login_left_div_input {
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 9px;
  min-width: 278px;
  height: 48px;
  padding-left: 1.25rem;
  margin-top: -10px !important;
}

.login_left_div_labels_password {
  margin-top: 1.875rem;
  width: 278px;
  font-style: normal;
  font-family: "Red Hat Display 500";
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.login_left_div_select {
  margin-top: -10px !important;
  box-sizing: border-box !important;
  border: 1px solid #cccccc !important;
  border-radius: 9px !important;
  width: 278px !important;
  height: 48px !important;
}
.login_left_div_select > button {
  border: 0px !important;
}
.login_page_left_div_submit_btn {
  margin-top: 1.6rem;
  width: 278px;
  height: 48px;
  background: #20356b;
  border-radius: 8px;
  color: #ffffff;
  font-style: normal;
  font-family: "Red Hat Display 500";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  border: none;
}
.login_left_div_forget_password {
  font-style: normal;
  font-family: "Red Hat Display 500";
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #20356b;
  text-align: right;
  width: 278px;
  margin-top: 10px;
}
.login_left_div_register {
  text-align: center;
  font-style: normal;
  font-family: "Red Hat Display 500";
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #677788;
  width: 278px;
  margin-top: 10px;
}
.login_page_left_inner_div {
  margin: auto;
  text-align: center;
}
.text-left {
  text-align: left !important;
}
/* @media (min-width: 992px) {
    .login_page_left_inner_div {
      margin-left: 3.875rem;
    }
  } */

/* @media (max-width: 991px) {
    .login_page_left_div {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
    .login_left_div_select {
      margin: auto;
    }
    .login_left_div_labels_Email {
      margin: 0px auto;
      margin-bottom: 15px !important;
      text-align: left;
    }
    .login_left_div_labels_password {
      margin: 20px auto;
      margin-bottom: 15px !important;
      text-align: left;
    }
    .login_left_div_forget_password {
      margin: 20px auto;
      margin-bottom: 15px !important;
      text-align: right;
    }
    .login_left_div_register {
      margin: 20px auto;
      margin-bottom: 15px !important;
      text-align: center;
    }
  }   */
.login-page-main > .container-fluid {
  padding-right: 0px;
}

.input_error {
  border: 1px solid red !important;
}

.input_error:focus {
  outline-offset: 0px !important;
  outline: none !important;
  border: 1px solid red !important;
  box-shadow: 0 0 3px red !important;
  -moz-box-shadow: 0 0 3px red !important;
  -webkit-box-shadow: 0 0 3px red !important;
}
