.dashboard_content_main {
  height: 100vh !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.paymentPage h1 {
  color: #88b04b;
  font-family: "Red Hat Display";
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.paymentPage p,
.paymentFailed p {
  color: #404f5e;
  font-family: "Red Hat Display";
  font-size: 20px;
  margin: 0;
}
.paymentPage i,
.paymentFailed i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}
.paymentPage .card,
.paymentFailed .card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

.paymentFailed h1 {
  color: red;
  font-family: "Red Hat Display";
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}
.hyper_link_text {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
