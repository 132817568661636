@media (max-width: 991px) {
    .error_message {
        margin: 0px auto !important;
        margin-bottom: 15px !important;
        text-align: left;
        width: 278px;
        margin-top: 4px !important;
    }
}

.error_message {
    width: 278px;
    color: red !important;
    margin-left: 10px;
    font-size: 13px;
    text-align: left;
    margin-top: 4px;
    font-style: normal;
    font-weight: 400;
    color: #000000;
}