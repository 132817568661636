.dashboard_home_main{
    padding-bottom: 30px;
}
.dashboard_home_page_div {
    padding-top: 45.16px;
    text-align: center;
}

.dashboard_home_page_div_heading {
    margin-top: 20px;
    width: 416px;
    font-weight: 800;
    font-family: 'Red Hat Display 800';
    font-size: 36px;
    line-height: 116.8%;
    text-align: center;
    color: #000000;
}

.dashboard_home_page_div_submit_btn {
    width: 293px;
    height: 48px;
    background: #377DFF;
    border-radius: 9px;
    font-family: 'Red Hat Display 500';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    color: #FFFFFF;
    border: none;
    text-align: center;
}

.dashboard_home_page_labels_Domain {
    text-align: left;
    font-weight: 500;
    font-family: "Red Hat Display 500";
    font-size: 15px;
    line-height: 20px;
    margin-left: 8px;
    color: #000000;
    margin-bottom: 7px;
}

.dashboard_home_page_input {
    width: 278px;
    height: 48px;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 9px;
    padding-left: 20px;
    font-weight: 300;
    font-size: 16px;
    color:black;
}

.dashboard_home_page_input_div {
    margin: 10px 15px
}

.dashboard_home_page_input_main_div {
    padding-top: 25px;

}

.dashboard_home_page_labels_country {
    text-align: left;
    font-weight: 500;
    font-size: 15px;
    margin-left: 8px;
    line-height: 20px;
    font-family: "Red Hat Display 500";
    color: #000000;
    margin-bottom: 17px;

}

.dashboard_home_page_country_select {
    margin-top: -10px !important;
    box-sizing: border-box !important;
    border: 1px solid #CCCCCC !important;
    border-radius: 9px !important;
    width: 278px !important;
    height: 48px !important;

}

.dashboard_home_page_country_select>button {
    border: 0px !important;
    color: #C1C1C1;
}

.dashboard_home_page_recent_result_div {
    margin-top: 24px;
    width: 567px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: #377DFF;
    text-align: left;

}

.dashboard_home_page_recent_result_domain_div {
    background: #FFFFFF;
    border: 1px solid #CCCCCC;
    border-radius: 9px;
    height: 48px;
    width: 561px;
    font-weight: 500;
    font-size: 14px;
    color: #8B8B8B;
    margin: 4px auto;
}

.dashboard_home_page_recent_result_domain {
    margin-left: 22px;
}

.dashboard_home_page_recent_result_domain_download {
    margin-right: 15px;
}

.dashboard_home_page_organic_keywords_div {
    margin: 30px auto;
    width: 567px;
    text-align: left;
    padding-left: 27px;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
    text-transform: capitalize;
    color: #898989;
    
}

.dashboard_home_page_organic_keywords_label {
    margin-left: 11px;
    font-family: "Red Hat Display 500";
}

.dashboard_home_page_organic_keywords_radio_div {
    margin: 10px 0px;
}

.dashboard_home_page_OR_div {
    width: 567px;
    text-align: center;
    margin: 29px 0px;
    font-family: "Red Hat Display 700";
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
}
.dashboard_home_page_organic_keywords_radio_div> .form-check-input{
    border:2px solid #898989;
}



.dashboard_homepage_text_area{
    font-weight: 300;
    font-size: 16px;
    color:black;
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    border-radius: 9px;
    width: 567px;
    min-height: 181px;
    padding: 6px;
}



.dashboard_home_page_upload_file_div{
position: relative;
background: #FFFFFF;
border: 1px dashed #377DFF;
border-radius: 9px;
width: 567px;
height: fit-content;
margin: auto;
}
.dashboard_home_page_upload_file_div >img{
    margin-top: 20px;
    margin-bottom: 30px;
}
.dashboard_home_page_uploaded_file_name{
    margin-top: -15px;
    font-family: "Red Hat Display 500";
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 19px;
   color: #8B8B8B;
}
.dashboard_home_page_upload_file_heading{
font-weight: 600;
font-size: 15px;
    font-family: "Red Hat Display 600";
    line-height: 20px;
color: #000000;
}
.dashboard_home_page_organic_keywords_radio_div >input:focus{
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
    accent-color: green;
}

/* .dashboard_home_page_organic_keywords_radio_div >input:checked{
    border-color: red;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
    accent-color: green;
    background-color: red;
} */

    .form-check-input:checked {
        background-color: #377dff !important;
        border-color: #377dff !important;
    }
    .loader_padding_226
    {
        padding-left: 265px !important;
    }
    .loader_padding_65
    {
        padding-left: 65px !important;
    }

   