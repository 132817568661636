.header_unblock_premium_btn {
    box-sizing: border-box;
    border: 1px solid #377DFF;
    border-radius: 8px;
    width: 176px;
    height: 40px;
    background-color: white;
    font-family: 'Red Hat Display 700';
    font-weight: 700;
    font-size: 13px;
    line-height: 17px;
    color: #377DFF;
    margin-right: 10.5px;
}

.header_unblock_premium_btn>span {
    margin-left: 4.76px;
}

.header_credit_span {
    font-weight: 500;
    font-family: 'Red Hat Display 500';
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    margin-right: 24px;
}
.header_span
{
    position: absolute;
    height: 62px;
    z-index: 99999;
    padding-top: 20px;
    padding-right: 13px;
    border-right: 0.01px solid;
    border-color: #e9e4e4;
    font-size: 19px;
    font-family: 'Red Hat Display 700';
    font-weight: bold
}
.domain_heading_padding
{
    padding-left: 28px;
    padding-top: 3px;
}

.header_buy_credit_btn {
    width: 131px;
    font-weight: 700;
    font-family: 'Red Hat Display 700';
    font-size: 13px;
    line-height: 17px;
    color: #377DFF;
    background-color: #FFFFFF;
    border: 1px solid #377DFF;
    border-radius: 8px;
    height: 40px;
}