.bg_theme_color_blue {
  background-color: #377dff;
}

.forget_password_text_register_blue {
  color: #377dff;
}

.forget_password_page_min_height {
  min-height: 100vh;
}

.forget_password_left_div_details {
  width: 411px;
  text-align: start;
  font-style: normal;
  font-size: 15px;
  line-height: 17px;
  font-family: "Red Hat Display 600";
  color: #000000;
}

.forget_password_page_right_div_btn {
  width: 180px;
  height: 48px;
  color: #377dff;
  background-color: #ffffff;
  border: 1px solid #377dff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
  border-radius: 11px;
  font-family: "Red Hat Display 500";
  margin: 0.625rem 0.219rem;
}

.forget_password_left_div_logo {
  margin-top: 5.938rem;
}

.forget_password_page_heading {
  margin-top: 1.188rem;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  font-family: "Red Hat Display 900";
  line-height: 64px;
  color: #000000;
}

.forget_password_left_div_labels_Email {
  margin-top: 2rem;
  width: 278px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.forget_password_left_div_input {
  box-sizing: border-box;
  border: 1px solid #cccccc;
  border-radius: 9px;
  min-width: 278px;
  height: 48px;
  padding-left: 1.25rem;
  margin-top: -10px !important;
}

.forget_password_left_div_labels_password {
  margin-top: 1.875rem;
  width: 278px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
}

.forget_password_left_div_select {
  margin-top: -10px !important;
  box-sizing: border-box !important;
  border: 1px solid #cccccc !important;
  border-radius: 9px !important;
  width: 278px !important;
  height: 48px !important;
}

.forget_password_left_div_select > button {
  border: 0px !important;
}

.forget_password_page_left_div_submit_btn {
  margin-top: 1.6rem;
  width: 278px;
  height: 48px;
  background: #377dff;
  border-radius: 8px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  border: none;
}

.forget_password_left_div_forget_password {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #377dff;
  text-align: right;
  width: 278px;
  margin-top: 10px;
}

.forget_password_left_div_register {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #677788;
  width: 278px;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .forget_password_page_left_inner_div {
    margin-left: 3.875rem;
  }
}

@media (max-width: 991px) {
  .forget_password_page_heading {
    padding: 0px 30px;
  }
  .forget_password_left_div_details {
    padding: 0px 40px;
  }
  .forget_password_page_left_div {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .forget_password_left_div_select {
    margin: auto;
  }

  .forget_password_left_div_labels_Email {
    margin: 0px auto;
    margin-bottom: 15px !important;
    text-align: left;
  }

  .forget_password_left_div_labels_password {
    margin: 20px auto;
    margin-bottom: 15px !important;
    text-align: left;
  }

  .forget_password_left_div_forget_password {
    margin: 20px auto;
    margin-bottom: 15px !important;
    text-align: right;
  }

  .forget_password_left_div_register {
    margin: 20px auto;
    margin-bottom: 15px !important;
    text-align: center;
  }
}
